import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import EasySection from "../components/easy_section"
import ChromeSection from "../components/chrome_section"
import DomainSection from "../components/domain_section"
import UsersSection from "../components/users_section"
import DeliverySection from "../components/delivery_section"
import NotificationsSection from "../components/notifications_section"
import ShareSection from "../components/share_section"
import DashboardSection from "../components/dashboard_section"
import DetailsSection from "../components/details_section"
import CallToActionSection from "../components/call_to_action_section"
import Img from 'gatsby-image';
import CallToActionButton from "../components/call_to_action_button"

class Home extends React.Component {
  scrollDown() {
    var sections = document.getElementsByClassName("section");
    if (!sections || sections.length < 2) {
      return;
    }
    const y = sections[1].getBoundingClientRect().top + window.pageYOffset - 64;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Get push notifications when your links get clicked on" description="Hyperlink is the easiest way to find out if your links are getting clicked on. No need for expensive and complicated analytics tools that only show you yesterday's statistics." slug="/" />
        <div className="section above-the-fold">
          <div className="container">
            <Push linkName="Twitter Link" />
            <div className="hero">
              <h2>Give your links Superpowers 🦸‍♂️</h2>
              <div className="subtitle">Find out when your links get clicked on.</div>
              <CallToActionButton />
              <div onClick={this.scrollDown.bind(this)} className="material-icons down-arrow">keyboard_arrow_down</div>
            </div>
          </div>
        </div>
        <NotificationsSection />
        <DeliverySection />
        <ShareSection />
        <DomainSection />
        <EasySection />
        <CallToActionSection />
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    playImage: file(relativePath: {eq: "assets/download_play.png" }) {
          childImageSharp {
          fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
        }
      }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
}
`
